import 'react-app-polyfill/stable'
import 'utils/prepare'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ConfigProvider, Spin } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import { StyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs'

import 'index.css'
import '@hz-insights/web-components/dist/style.css'
import Vmp from 'Vmp'
import reportWebVitals from 'reportWebVitals'
import { AuthProvider } from 'Auth/AuthContext'
import { AbilityProvider } from 'Casl/AbilityContext'
import { PersonalizedProvider } from 'Personalized/PersonalizedContext'
import { NoteProvider } from 'Note/NoteContext'
import { MediaProvider } from 'Context/Media/MediaContext'
import { PlayerProvider } from '@hz-insights/web-components'
import { CompatibleRouterProvider } from 'Context/CompatibleRouter/CompatibleRouterContext'
import ScrollToTop from 'components/ScrollToTop'

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<Spin />}>
      <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
        <ConfigProvider
          locale={zhCN}
          theme={{
            token: { colorPrimary: '#1890ff' }
          }}
        >
          <Router basename={process.env.REACT_APP_BASE_NAME}>
            <CompatibleRouterProvider>
              <ScrollToTop />
              <AuthProvider>
                <AbilityProvider>
                  <PersonalizedProvider>
                    <MediaProvider>
                      <NoteProvider>
                        <PlayerProvider>
                          <Vmp />
                        </PlayerProvider>
                      </NoteProvider>
                    </MediaProvider>
                  </PersonalizedProvider>
                </AbilityProvider>
              </AuthProvider>
            </CompatibleRouterProvider>
          </Router>
        </ConfigProvider>
      </StyleProvider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
