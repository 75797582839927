import React, { useEffect, useState } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import useRequest from '@ahooksjs/use-request'

import { Avatar, Col, Layout, Menu, Row, Dropdown, Badge } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'

import { useAuth } from 'Auth/AuthContext'
import { usePersonalized } from 'Personalized/PersonalizedContext'
import { AbilityContext } from 'Casl/AbilityContext'
import { Action, Subject } from 'Casl/Casl.service'
import styles from './css/Header.module.scss'
import { QAcommunityService } from 'QAcommunity/QAcommunity.service'

type MenuItem = Required<MenuProps>['items'][number]
const getItem = (
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem => {
  if (!label) return null
  return {
    key,
    icon,
    children,
    label,
    type
  } as MenuItem
}
interface IHeaderProps {
  source: 'admin' | 'home'
}
// 凡是vmp.tsx中Route的key={'home'}，都来一套
const urlList = [
  { path: '/ereports', key: 'ereports' },
  { path: '/qac', key: 'QAcommunity' },
  { path: '/collections/market_review', key: 'market_review' },
  { path: '/collections/industry_analysis', key: 'industry_analysis' },
  { path: '/charts/favorites', key: 'favorites' },
  { path: '/editor', key: 'system' }, // 特殊处理下，含有editor都为后台，路由可能包含有editor的不能写在editor之前
  { path: '/articles', key: 'index' }, // 查看报告详情为首页
  { path: '/collections/custom', key: 'index' }, // 预览自定义图表集为首页
  { path: '/charts', key: 'index' }, // 查看charts为首页
  { path: '/collections', key: 'collections' },
  { path: '/chat', key: 'chat' },
  { path: '/sr', key: 'sr' }
]

const Header: React.FC<IHeaderProps> = props => {
  const location = useLocation()
  const personalized = usePersonalized()
  const auth = useAuth()
  const { chartID }: { chartID: string } = useParams()
  const ability = React.useContext(AbilityContext)
  const [activityKey, setActivityKey] = useState<string[]>([])
  const [temporaryKey, setTemporaryKey] = useState<string[]>()
  const { data: mentionMeCount } = useRequest(QAcommunityService.getMentionMeCount, {
    formatResult: res => res?.data?.data
  })
  const menuChange = () => {
    const menuList: MenuItem[] = []
    if (ability.can(Action.Read, Subject.Admin) && location.pathname !== '/' && props.source === 'home') {
      const editList = ['chat', '/qac', '/ereports', '/charts/favorites', '/collections']
      const find = editList.filter(item => location.pathname.includes(item))
      if (!find.length)
        menuList.push(
          getItem(
            <Link
              to={location.pathname.includes('/charts') ? `/charts/${chartID}/editor` : `${location.pathname}/editor`}
              target="_blank"
              rel="noopener noreferrer"
            >
              编辑
            </Link>,
            'editor'
          ) as MenuItem
        )
    }
    return menuList
  }
  const heardItems: MenuItem[] = [
    getItem(<Link to="/">首页</Link>, 'index'),
    ability.can(Action.Read, Subject.Admin) ? getItem(<Link to="/articles">后台</Link>, 'system') : getItem(''),
    ...menuChange(),
    ability.can(Action.Read, Subject.QAcommunity)
      ? getItem(
          <Badge.Ribbon
            text={mentionMeCount}
            style={{
              display: activityKey?.[0] !== 'QAcommunity' && mentionMeCount ? '' : 'none',
              position: 'absolute',
              top: '0px',
              right: '-15px'
            }}
          >
            <Link to="/qac" target="_blank" rel="noreferrer">
              问答社区
            </Link>
          </Badge.Ribbon>,
          'QAcommunity'
        )
      : getItem(''),
    ability.can(Action.Read, Subject.Admin)
      ? getItem(
          <Link to="/ereports" target="_blank" rel="noreferrer">
            外部研报
          </Link>,
          'ereports'
        )
      : getItem(''),
    getItem(<Link to={`/collections`}>精选看板</Link>, 'collections'),
    getItem(
      <Link to={`/charts/favorites`} target="_blank" rel="noreferrer">
        数据思维
      </Link>,
      'favorites'
    ),
    ability.can(Action.Read, Subject.Admin) ? getItem(<Link to={`/rag`}>Rag</Link>, 'rag') : null,
    auth.userInfo?.invite ? getItem(<Link to={`/sr`}>SR</Link>, 'sr') : null,
    props.source === 'home' ? getItem('关于我们', 'about_us') : getItem(''),
    getItem(
      auth.jwt ? (
        <Dropdown
          menu={{
            items: [
              {
                label: `用户名: ${auth.userInfo?.name}`,
                key: 'userName'
              },
              {
                label: `电话号码: ${auth.userInfo?.phone_number}`,
                key: 'phoneNumber'
              },
              {
                label: <span onClick={auth.logout}>退出</span>,
                key: 'logout'
              }
            ]
          }}
        >
          <Avatar
            src={auth.userInfo?.avatar}
            style={{ marginLeft: '20px' }}
            shape={'circle'}
            icon={<UserOutlined style={{ fontSize: '20px' }} />}
          />
        </Dropdown>
      ) : (
        <Link to={`/login?next=${location.pathname}`}>登录</Link>
      ),
      auth.jwt ? 'info' : 'login'
    )
  ]
  useEffect(() => {
    handleChangeMenuKey()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeMenuKey = () => {
    if (location.pathname === '/') {
      setActivityKey(['index'])
      return
    }
    // 查看总览页的都为后台
    const specialUrl = ['/articles', '/collections/custom', '/charts']
    const findUrl = specialUrl.find(item => location.pathname === item)
    if (findUrl) {
      setActivityKey(['system'])
      return
    }
    const findKey = urlList.find(item => location.pathname.includes(item.path))?.key
    setActivityKey(findKey ? [findKey] : ['system'])
  }

  const handleClickMenu: MenuProps['onClick'] = e => {
    // 点击不高亮的数据
    const notActivityKey = ['/qac', 'ereports', 'market_review', 'industry_analysis', 'favorites']
    if (notActivityKey.includes(e.key)) return
    setTemporaryKey([e.key])
  }

  useEffect(() => {
    if (temporaryKey) {
      setActivityKey(temporaryKey)
    }
  }, [location])

  return (
    <Layout.Header className="header" style={{ background: personalized?.personalizedInfo?.header_background }}>
      <Row gutter={24}>
        <Col xs={18} sm={18} md={6} lg={4} xl={4}>
          <Link to="/" className={styles['logo-link']}>
            {personalized?.personalizedInfo?.logo_link && (
              <img src={personalized?.personalizedInfo?.logo_link} className={styles.headerLogo} alt="logo" />
            )}
          </Link>
        </Col>
        <Col xs={6} sm={6} md={18} lg={20} xl={20}>
          <Menu
            theme={!personalized?.personalizedInfo?.header_background ? 'dark' : undefined}
            style={{
              background: personalized?.personalizedInfo?.header_background,
              color: personalized?.personalizedInfo?.header_color
            }}
            mode="horizontal"
            className={styles.menu}
            items={heardItems}
            selectedKeys={activityKey}
            onClick={handleClickMenu}
          />
        </Col>
      </Row>
    </Layout.Header>
  )
}

export default Header
