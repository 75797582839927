import React, { useEffect } from 'react'
import { Route, useParams, useLocation } from 'react-router-dom'
import useRequest from '@ahooksjs/use-request'
import classNames from 'classnames'

import { Col, Layout, Row, Spin, Typography } from 'antd'

import { ChartService } from 'Chart/Chart.service'
import 'layout.css'
import { useNote } from 'Note/NoteContext'
import { Note } from 'Note/Note'
import { ColResize } from 'components/ColResize'
import Config from 'config'
import style from './css/Home.module.scss'
import Header from 'Header'
import { AbilityContext } from 'Casl/AbilityContext'
import { Action, Subject } from 'Casl/Casl.service'
import { VmpNoAuthority } from 'Vmp'

const EreportPDF = React.lazy(() => import('./Ereport/EreportPDF'))
const EreportAll = React.lazy(() => import('./Ereport/EreportAll'))
const QAcommunityAll = React.lazy(() => import('./QAcommunity/QAcommunityAll'))
const QAcommunity = React.lazy(() => import('./QAcommunity/QAcommunity'))
const QAAnswerEditor = React.lazy(() => import('./QAcommunity/QAAnswerEditor'))
const Custom = React.lazy(() => import('./Collections/Custom.container'))

const HomeIndex = React.lazy(() => import('./Article/HomeIndex'))
const Article = React.lazy(() => import('./Article/Article'))
const Chart = React.lazy(() => import('./Chart/Chart'))
const Model = React.lazy(() => import('./Model/Model'))
const Upload = React.lazy(() => import('./Upload/Upload'))
const ChartsFavorites = React.lazy(() => import('./Charts-favorites/ChartsFavorites'))
const MarketReview = React.lazy(() => import('./Collections/MarketReview'))
const IndustryAnalysis = React.lazy(() => import('./Collections/IndustryAnalysis'))
const Collections = React.lazy(() => import('./Collections/Collection'))
const Chat = React.lazy(() => import('./Chat/Chat'))
const ArticleNoAuthentication = React.lazy(() => import('./Article/ArticleNoAuthentication'))
const SR = React.lazy(() => import('./SR/SR'))

// 转换路由中的ID参数为number类型，同时路由中的(\\d+)正则匹配限定参数为数字
const ChartwithRoute: React.FC = () => {
  const { chartID, pathSetting }: { chartID: string; pathSetting: string } = useParams()
  const { stuffNoteEntrance } = useNote()
  const ability = React.useContext(AbilityContext)
  return (
    <>
      <div className={style['home-chart-wrapper']}>
        <Chart
          chartID={Number(chartID)}
          pathSetting={pathSetting}
          showReviews
          showAddReviews={ability.can(Subject.Admin, Action.Read)}
        />
      </div>
      {stuffNoteEntrance}
    </>
  )
}

const ArticlewithRoute: React.FC = () => {
  const { articleID }: { articleID: string } = useParams()
  return <Article articleID={Number(articleID)} />
}
const ModelwithRoute: React.FC = () => {
  const { modelID }: { modelID: string } = useParams()
  return <Model modelID={Number(modelID)} />
}

const UploadwithRoute: React.FC = () => {
  const { uploadName }: { uploadName: string } = useParams()
  return <Upload uploadName={uploadName} />
}

const EreportPDFWithRoute: React.FC = () => {
  const { EreportID }: { EreportID: string } = useParams()
  return <EreportPDF EreportID={Number(EreportID)} />
}

const CollectionsCustomwithRoute: React.FC = () => {
  const { customID }: { customID: string } = useParams()
  return <Custom customID={Number(customID)} />
}

const Home: React.FC = () => {
  const { isNoteOpen } = useNote()
  const { pathname } = useLocation()
  const { run: setMonitorMsg } = useRequest(ChartService.UserBehaviorMonitor, {
    manual: true,
    debounceInterval: 300
  })
  useEffect(() => {
    setMonitorMsg({
      app_module: 'page',
      action_type: 'GET',
      entity_info: undefined,
      app_url: pathname
    })
  }, [pathname])
  const ability = React.useContext(AbilityContext)
  return (
    <Layout>
      <Header source="home" />
      {/*64 70 30 分别代表 <header>的height、<footer>的height 以及 <content>的margin的值*/}
      <Layout.Content
        className={`${pathname.includes('/charts/favorites') ? 'no-min-height' : ''} ${style['home-layout']}`}
      >
        <React.Suspense
          fallback={
            <div className={style['home-suspense']}>
              <Spin />
            </div>
          }
        >
          <ColResize initialLeftPercent={isNoteOpen ? 50 : 100}>
            <div className={classNames({ [style['resize-left-content']]: isNoteOpen })}>
              <Route key={'home'} exact path={'/'} component={HomeIndex} />
              <Route key={'article'} exact path={'/articles/:articleID(\\d+)'} component={ArticlewithRoute} />
              <Route
                key={'chart'}
                exact
                path={['/charts/:chartID(\\d+)', '/charts/:chartID(\\d+)/:pathSetting']}
                component={ChartwithRoute}
              />
              {/* [\\u4e00-\\u9fa5]+ */}
              <Route
                key={'upload'}
                path={['/uploads/:uploadName([\\u4E00-\\u9FA5A-Za-z0-9_「」\\{\\}\\[\\]\\(\\)【】（）*]+)']}
                component={UploadwithRoute}
              />
              <Route key={'model'} exact path={['/models/:modelID(\\d+)']} component={ModelwithRoute} />
              {ability?.rules?.length > 0 ? (
                <Route
                  key={'QAcommunity'}
                  exact
                  path={'/qac'}
                  component={ability.can(Action.Read, Subject.Admin) ? QAcommunityAll : VmpNoAuthority}
                />
              ) : (
                <></>
              )}
              <Route
                key={'QAcommunityDetail'}
                exact
                path={['/qac/:QAID(\\d+)', '/qac/:QAID(\\d+)/answer/:openAnswerID']}
                component={QAcommunity}
              />
              <Route
                key={'QAAnswerEditor'}
                exact
                path={'/qac/:QAID(\\d+)/answer/:answerID(\\d+)/editor'}
                component={QAAnswerEditor}
              />
              <Route key={'ereports'} exact path={'/ereports'} component={EreportAll} />
              <Route key={'ereportsPDF'} exact path={'/ereports/:EreportID(\\d+)'} component={EreportPDFWithRoute} />
              <Route key="chartsFavorites" exact path="/charts/favorites" component={ChartsFavorites} />
              <Route key="marketReview" exact path="/collections/market_review" component={MarketReview} />
              <Route key="industryAnalysis" exact path="/collections/industry_analysis" component={IndustryAnalysis} />
              <Route
                key={'collectionscustom'}
                exact
                path={['/collections/custom/:customID(\\d+)', '/collections/custom/:customID(\\d+)/:pathSetting']}
                component={CollectionsCustomwithRoute}
              />
              <Route key="collections" exact path={'/collections'} component={Collections} />
              {ability?.rules?.length > 0 ? (
                <Route
                  key="chat"
                  exact
                  path={'/rag'}
                  component={ability.can(Action.Read, Subject.Admin) ? Chat : VmpNoAuthority}
                />
              ) : (
                <></>
              )}
              <Route
                key="articleNoAuthentication"
                exact
                path={'/articles/temporary-link'}
                component={ArticleNoAuthentication}
              />
              <Route key="sr" exact path={'/sr'} component={SR} />
            </div>
            {isNoteOpen && <Note />}
          </ColResize>
        </React.Suspense>
      </Layout.Content>
      <Layout.Footer id="aboutUs" className={style['home-footer']}>
        <Row gutter={24}>
          <Col xs={0} sm={0} md={0} lg={2} xl={4} />
          <Col xs={24} sm={24} md={24} lg={20} xl={16}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                {/*Team Info*/}
                <Typography.Title level={3} className="home-footer-title">
                  {Config.homeIntroTeamTitle}
                </Typography.Title>
                {/* <Typography.Paragraph style={{ textAlign: 'justify' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{Config.homeIntroTeamParagraph}
                </Typography.Paragraph> */}
              </Col>

              {/*Company Info*/}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Typography.Title level={3} className="home-footer-title">
                  {Config.homeIntroCompanyName}
                </Typography.Title>
                <Typography.Text>
                  <p>{Config.homeIntroCompanyAddress}</p>
                  <p>{Config.homeIntroCompanyPhoneNumber}</p>
                  <p>{Config.homeIntroCompanyEmail}</p>
                  <p>
                    <a href="https://www.hzinsights.com/index.html" className={style.backLink}>
                      {Config.homeIntroCompanyWebsite}
                    </a>
                  </p>
                  <p>
                    <a href="https://fi.hzinsights.com/" className={style.backLink}>
                      {Config.homeIntroFIWebsite}
                    </a>
                  </p>
                </Typography.Text>
              </Col>

              {/*Declaration Info*/}
              <Col span={24}>
                <Typography.Title level={3} className="home-footer-title">
                  {Config.homeDisclaimerTitle}
                </Typography.Title>
                <Typography.Paragraph style={{ textAlign: 'justify' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{Config.homeDisclaimerParagraph}
                </Typography.Paragraph>
              </Col>
            </Row>
          </Col>
          <Col xs={0} sm={0} md={0} lg={2} xl={4} />
        </Row>
      </Layout.Footer>
    </Layout>
  )
}

export default Home
