interface ILocalChartFormInfo {
  chartFormValue: any
  time: number
}

const CHART_EDITOR_FORM = 'chart_editor_form'
const EXPIRES = 3600 * 1000

export const storeChartEditorForm = ({ chartID, formValue }: { chartID: number; formValue: any }): void => {
  try {
    const localChartFormStr = localStorage.getItem(CHART_EDITOR_FORM)
    const localChartFormAll = localChartFormStr ? JSON.parse(localChartFormStr) : {}
    localChartFormAll[String(chartID)] = { chartFormValue: formValue, time: new Date().getTime() }
    localStorage.setItem(CHART_EDITOR_FORM, JSON.stringify(localChartFormAll))
  } catch (e) {
    console.error('图表编辑表单本地缓存失败')
  }
}

export const removeOutdatedChartEditorForms = (): void => {
  try {
    const localChartFormStr = localStorage.getItem(CHART_EDITOR_FORM)
    if (localChartFormStr) {
      const time = new Date().getTime()
      const localChartFormAll = JSON.parse(localChartFormStr)
      const _localChartFormAll = Object.fromEntries(
        Object.entries(localChartFormAll as { [key: string]: ILocalChartFormInfo }).filter(
          item => time - item[1].time < EXPIRES
        )
      )
      localStorage.setItem(CHART_EDITOR_FORM, JSON.stringify(_localChartFormAll))
    }
  } catch (e) {
    console.error(e)
  }
}

export const getLocalChartEditorForm = (chartID: number): any => {
  try {
    const localChartFormStr = localStorage.getItem(CHART_EDITOR_FORM)
    if (localChartFormStr) {
      return JSON.parse(localChartFormStr)[String(chartID)]?.chartFormValue
    }
  } catch (e) {
    console.error(e)
  }
}
