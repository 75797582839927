import React, { createContext, useState, useEffect } from 'react'
import useRequest from '@ahooksjs/use-request/es'
import { createContextualCan } from '@casl/react'
import { Ability } from '@casl/ability'

import { AbilityProvider as SubAbilityProvider } from '@hz-insights/web-components'

import { useAuth } from 'Auth/AuthContext'

import { CaslService, IPermission } from './Casl.service'

// { action: 'read', subject: 'loading' } 用于检测请求loading情况
export const AbilityContext = createContext<Ability>(new Ability([]))
export const Can = createContextualCan(AbilityContext.Consumer)

type ContextProviderProps = { children: React.ReactNode }
//provider
export const AbilityProvider: React.FC<ContextProviderProps> = ({ children }: ContextProviderProps) => {
  const { jwt } = useAuth()
  const [ability, setAbility] = useState<Ability>(new Ability([]))
  const { run: getSelfPermission } = useRequest(CaslService.getSelfPermission, {
    manual: true,
    formatResult: response => response.data.data,
    onSuccess: data => {
      setAbility(
        new Ability([
          ...data.map((item: IPermission) => ({ action: item.action, subject: item.subject })),
          { action: 'read', subject: 'loading' }
        ])
      )
    }
  })
  useEffect(() => {
    jwt ? getSelfPermission() : setAbility(new Ability([]))
  }, [jwt, getSelfPermission])
  return (
    <AbilityContext.Provider value={ability}>
      <SubAbilityProvider ability={ability}>{children}</SubAbilityProvider>
    </AbilityContext.Provider>
  )
}
