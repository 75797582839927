import React, { useEffect, useState, useRef } from 'react'

import classNames from 'classnames'
import { AxiosResponse } from 'axios'

import { Space, Popover, Input } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'

import { IUpdateNoteNodeParam, ICreateNoteNodeParam, ICreateNoteNodeInfo } from './Note.service'
import { IResponseBase } from '@hz-insights/web-components'

import style from './css/NoteNode.module.scss'

export interface ITreeNode {
  title: string
  id: number
  type: number
  children?: ITreeNode[]
}

const redMode = 'redmode'
const editMode = 'editmode'

const NoteNode: React.FC<{
  treeNode: ITreeNode
  selected: boolean
  updateNoteNode: (
    noteID: number,
    updataNodeParams: IUpdateNoteNodeParam
  ) => Promise<AxiosResponse<IResponseBase<void>>>
  createNoteNode: (createNodeParams: ICreateNoteNodeParam) => Promise<AxiosResponse<IResponseBase<ICreateNoteNodeInfo>>>
  deleteNoteNode: (noteID: number) => Promise<AxiosResponse<IResponseBase<void>>>
}> = ({ treeNode, selected, updateNoteNode, createNoteNode, deleteNoteNode }) => {
  const [nodeDisplaymode, setNodeDisplaymode] = useState<'redmode' | 'editmode'>('redmode')

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const inputRef = useRef<Input>(null)

  const createFolder = () => {
    createNoteNode({
      parent: treeNode.id,
      type: 1,
      title: '新建文件夹'
    })
  }
  const createFile = () => {
    createNoteNode({
      parent: treeNode.id,
      type: 2,
      title: '新建笔记'
    })
  }
  const reName = () => {
    setNodeDisplaymode(editMode)
    window.setTimeout(() => {
      inputRef.current && inputRef.current.focus()
    }, 0)
  }

  const deleteNode = () => {
    deleteNoteNode(treeNode.id)
  }

  useEffect(() => {
    setNodeDisplaymode(redMode)
  }, [treeNode])

  return (
    <div className={classNames(style['node-title'], { [style['node-title-selected']]: selected })}>
      {nodeDisplaymode === 'redmode' ? (
        <>
          <span>{treeNode.title}</span>
          <span style={{ marginLeft: '6px', flexShrink: 0 }} className={style['node-title-operation']}>
            <Popover
              placement="bottom"
              trigger="hover"
              content={
                <Space
                  direction="vertical"
                  onClick={e => {
                    e.stopPropagation()
                  }}
                >
                  {treeNode.type === 1 && (
                    <div className={style['operation-item']} onClick={createFile}>
                      新建笔记
                    </div>
                  )}
                  {treeNode.type === 1 && (
                    <div className={style['operation-item']} onClick={createFolder}>
                      新建文件夹
                    </div>
                  )}
                  {treeNode.type === 1 && (
                    <div className={style['operation-item']} onClick={reName}>
                      重命名
                    </div>
                  )}

                  <div className={style['operation-item']} onClick={deleteNode}>
                    删除
                  </div>
                </Space>
              }
            >
              <EllipsisOutlined
                style={{ color: '#1890FF' }}
                onClick={e => {
                  e.stopPropagation()
                }}
              />
            </Popover>
          </span>
        </>
      ) : (
        <Input
          ref={inputRef}
          size="small"
          placeholder={treeNode.title}
          onBlur={() => {
            setNodeDisplaymode(redMode)
          }}
          onPressEnter={e => {
            updateNoteNode(treeNode.id, { title: (e.target as any).value }).then(res => {
              if (res.data.code === 0) {
                inputRef.current && inputRef.current.blur()
              }
            })
          }}
        />
      )}
    </div>
  )
}

export { NoteNode }
