import React, { ReactElement, ReactNode, useContext, useState, useEffect, useRef } from 'react'

import { useLocation } from 'react-router-dom'

import { FloatButton } from 'antd'
import { EditOutlined, CloseCircleOutlined } from '@ant-design/icons'

import { isStaff } from '../utils/isStaff'
import { useMedia } from 'Context/Media/MediaContext'
import { AbilityContext } from 'Casl/AbilityContext'

interface NoteContext {
  isNoteOpen: boolean
  noteContextState: InoteContextState
  setNoteContextState: React.Dispatch<React.SetStateAction<InoteContextState>>
  noteEntrance: ReactElement
  stuffNoteEntrance: ReactElement
  addToFroalaRef: React.MutableRefObject<
    (({ addedText, url, urlTitle }: { addedText: string; url: string; urlTitle?: string }) => void) | null
  >
}

interface InoteContextState {
  selectedKeys: string[]
  expandedKeys: string[]
  searchKey: string
  noteID: number | undefined
}

const noteContext = React.createContext<NoteContext | undefined>(undefined)

const NoteProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { pathname } = useLocation()
  const ability = React.useContext(AbilityContext)
  const [isNoteOpen, setIsNoteOpen] = useState(false)
  const [noteContextState, setNoteContextState] = useState<InoteContextState>({
    selectedKeys: [],
    expandedKeys: [],
    searchKey: '',
    noteID: undefined
  })
  const addToFroalaRef = useRef<() => void>(null)
  const media = useMedia()

  //路由切换时关闭笔记
  useEffect(() => {
    const regexpArray = [
      /^\/$/,
      /^\/articles\/\d+$/,
      /^\/ereport\/\d+$/,
      /^\/charts\/favorites$/,
      /^\/charts\/\d+$/,
      /^\/ereports$/,
      /^\/ereports\/\d+$/
    ]
    !regexpArray.some(regexp => regexp.test(pathname)) && setIsNoteOpen(false)
  }, [pathname])

  const noteEntrance =
    isStaff(ability) && !media.isSmallMax ? (
      <FloatButton
        style={{ bottom: 150 }}
        icon={isNoteOpen ? <CloseCircleOutlined></CloseCircleOutlined> : <EditOutlined></EditOutlined>}
        tooltip={isNoteOpen ? '点击关闭笔记' : '点击打开笔记'}
        onClick={() => {
          setIsNoteOpen(!isNoteOpen)
        }}
      />
    ) : (
      <></>
    )

  const stuffNoteEntrance = isStaff(ability) ? noteEntrance : <></>

  return (
    <noteContext.Provider
      value={{
        isNoteOpen,
        noteContextState,
        setNoteContextState,
        noteEntrance,
        stuffNoteEntrance,
        addToFroalaRef
      }}
    >
      {children}
    </noteContext.Provider>
  )
}

const useNote = (): NoteContext => useContext(noteContext as React.Context<NoteContext>)

export { NoteProvider, useNote }
