import React from 'react'
import { useMediaQuery } from 'react-responsive'

import { MediaProvider as SubMediaProvider } from '@hz-insights/web-components'

export interface MediaContext {
  isBig: boolean
  isSmallMax: boolean
  isBigMax: boolean
  isWrap: boolean
}

// 创建context
export const MediaContexts = React.createContext<MediaContext | undefined>(undefined)

//provider参数类型
type ContextProviderProps = { children: React.ReactNode }
//provider
const MediaProvider: React.FC<ContextProviderProps> = ({ children }: ContextProviderProps) => {
  const isBig = useMediaQuery({ query: '(min-width: 780px)' })
  const isSmallMax = useMediaQuery({ query: '(max-width: 926px)' })
  const isBigMax = useMediaQuery({ query: '(max-width: 1280px)' })
  const isWrap = useMediaQuery({ query: '(min-width: 1622px)' })
  const value = {
    isBig,
    isSmallMax,
    isBigMax,
    isWrap
  }
  return (
    <MediaContexts.Provider value={value}>
      <SubMediaProvider media={value}>{children}</SubMediaProvider>
    </MediaContexts.Provider>
  )
}

const useMedia = (): MediaContext => {
  const context: MediaContext | undefined = React.useContext(MediaContexts)
  if (context === undefined) {
    throw new Error('useContext must be used within a ContextProvider ~')
  }
  return context
}

export { MediaProvider, useMedia }
