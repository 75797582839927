import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch, Link, useLocation } from 'react-router-dom'
// eslint-disable-next-line
import 'swiper/swiper-bundle.css'

import { Result, Button } from 'antd'

import { useAuth } from 'Auth/AuthContext'
import { useSearchParams } from 'hooks/useSearchParams'
import { removeOutdatedChartEditorForms } from 'utils/localChartEditorForm'
import Home from 'Home'

const Admin = React.lazy(() => import('./Admin'))
// const Home = React.lazy(() => import('./Home'))
const Display = React.lazy(() => import('./Display'))
const Login = React.lazy(() => import('./Auth/Login'))
const LoginWechatLanding = React.lazy(() => import('./Auth/LoginWechatLanding'))
const QAcommunityDisplay = React.lazy(() => import('./QAcommunity/QAcommunityDisplay'))
const ChartDisplay = React.lazy(() => import('./Chart/ChartDisplay'))
const PictureAmplifier = React.lazy(() => import('./components/PictureAmplifier'))
const SlideShow = React.lazy(() => import('./Slides/SlideShow'))
const MixedSignupLogin = React.lazy(() => import('./components/MixedSignupLogin/MixedSignupLogin'))

const HKLoginWechatlaunch = React.lazy(() => import('./Auth/HKLoginWechatlaunch'))
const FILoginWechatlaunch = React.lazy(() => import('./Auth/FILoginWechatlaunch'))

const NlsDetailFrontend = React.lazy(() => import('./Nls/NlsDetailFrontend'))
const CustomDisplay = React.lazy(() => import('./Collections/CustomDisplay'))
const SR = React.lazy(() => import('./SR/SR'))

export const VmpNotfound: React.FC = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="抱歉，您访问的页面不存在~"
      extra={
        <Button type="primary">
          <Link to="/">Back Home</Link>
        </Button>
      }
      style={{ margin: '5% auto' }}
    />
  )
}

export const VmpNoAuthority: React.FC = () => {
  return (
    <Result
      status="403"
      title="401"
      subTitle="抱歉，您当前还没有获得该页面的访问权限哦~"
      extra={
        <Button type="primary">
          <Link to="/">Back Home</Link>
        </Button>
      }
      style={{ margin: '5% auto' }}
    />
  )
}

export const VmpSeverError: React.FC = () => {
  return (
    <Result
      status="500"
      title="500"
      subTitle="抱歉，发生了点小问题，我们正在抓紧修复中..."
      extra={
        <Button type="primary">
          <Link to="/">回到主页</Link>
        </Button>
      }
      style={{ margin: '5% auto' }}
    />
  )
}

export const VmpNotPublish: React.FC = () => {
  return (
    <Result
      status="warning"
      title="该报告/图表暂未发布"
      extra={
        <Button type="primary">
          <Link to="/">回到主页</Link>
        </Button>
      }
      style={{ margin: '5% auto' }}
    />
  )
}

export const VmpNoAuthentication: React.FC = () => {
  const { pathname } = useLocation()
  return (
    <Result
      status="403"
      title="未登录"
      subTitle="您还没有登录哦~"
      extra={
        <Button type="primary">
          <Link to={`/login?${new URLSearchParams({ next: pathname }).toString()}`}>登录</Link>
        </Button>
      }
      style={{ margin: '5% auto' }}
    />
  )
}

const Vmp: React.FC = () => {
  const auth = useAuth()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const location = useLocation()
  useEffect(() => {
    // auth.getProfile()
    if (token) {
      if (!auth.jwt) {
        auth.loginEnd(token, location.pathname)
      } else {
        console.log(1)
      }
    }
  }, [])
  useEffect(removeOutdatedChartEditorForms, [])
  return (
    <Switch>
      <Route
        key={'admin'}
        exact
        path={[
          '/users',
          '/users/:mode/:initKeyword',
          '/users/statistics',
          '/users/pvuv',
          '/users/statisticslist',
          '/users/spg',
          '/personalized',
          '/articles',
          '/articletags',
          '/calendar',
          '/roadShowFeedback',
          '/charts/codeblock',
          '/charts/codeblock/:codeBlockId/editor',
          '/charts/tmplsetting',
          '/charts/tmpls',
          '/charts/tmplsetting/:tmplsettingID(\\d+)/editor',
          '/charts',
          '/chartMonitor',
          '/models',
          '/models/statement',
          '/uploads',
          '/uploads/analysis',
          '/articles/:articleID(\\d+)/editor',
          '/charts/:chartID(\\d+)/editor',
          '/charts/tmpl/:chartTemplateID(\\d+)/editor',
          '/models/:modelID(\\d+)/editor',
          '/models/statement/:modelID(\\d+)/editor',
          '/uploads/:uploadName([\\u4E00-\\u9FA5A-Za-z0-9_「」\\{\\}\\[\\]\\(\\)【】（）*]+)/editor',
          '/scharts',
          '/nls',
          '/nls/:nlsID(\\d+)/detail',
          '/note',
          '/collections/custom',
          '/collections/custom/:customID(\\d+)/editor',
          '/slides',
          '/slides/editor/:slideID(\\d+)',
          '/slides/overview/:slideID(\\d+)',
          '/dashboard',
          '/meeting-project',
          '/meeting-project/:id/summary',
          '/stockCoverage',
          '/fi/page',
          '/fi/videos',
          '/fi/users'
        ]}
      >
        <Admin />
      </Route>
      <Route
        key={'display'}
        exact
        path={[
          '/models/:modelID(\\d+)',
          '/models/statement/:modelID(\\d+)',
          `/uploads/:uploadName([\\u4E00-\\u9FA5A-Za-z0-9_「」\\{\\}\\[\\]\\(\\)【】（）*]+)`
        ]}
      >
        <Display />
      </Route>
      <Route
        key={'home'}
        exact
        path={[
          '/articles/:articleID(\\d+)',
          '/charts/:chartID(\\d+)/:pathSetting',
          '/charts/:chartID(\\d+)',
          '/',
          '/qac',
          '/qac/:QAID(\\d+)',
          '/qac/:QAID(\\d+)/answer/:openAnswerID',
          '/qac/:QAID(\\d+)/answer/:answerID(\\d+)/editor',
          '/ereports',
          '/ereports/:EreportID(\\d+)',
          '/charts/favorites',
          '/collections/market_review',
          '/collections/industry_analysis',
          '/collections/custom/:customID(\\d+)',
          '/collections/custom/:customID(\\d+)/:pathSetting',
          '/collections',
          '/rag',
          '/articles/temporary-link'
        ]}
      >
        <Home />
      </Route>
      <Route key={'PicAmplifier'} exact path={['/PicAmplifier/:PicLink']}>
        <PictureAmplifier />
      </Route>
      <Route
        key={'qadisplay'}
        exact
        path={['/qac/display/:QAID(\\d+)', '/qac/display/:QAID(\\d+)/answer/:openAnswerID(\\d+)']}
      >
        <QAcommunityDisplay />
      </Route>
      <Route
        key={'chartdisplay'}
        exact
        path={['/charts/display/:chartID(\\d+)', '/charts/display/:chartID(\\d+)/:pathSetting']}
      >
        <ChartDisplay />
      </Route>
      <Route key={'customdisplay'} exact path={['/collections/custom/display/:customID(\\d+)']}>
        <CustomDisplay />
      </Route>
      <Route key={'slides/show'} exact path={['/slides/show/:slidesID(\\d+)']}>
        <div style={{ width: '100vw', height: '100vh' }}>
          <SlideShow />
        </div>
      </Route>
      {/* <Route key={'login'} exact path={'/login'}>
        <Login />
      </Route> */}
      <Route key={'login/password'} exact path={'/login/password'}>
        <Login />
      </Route>
      <Route key={'login/wechat'} path={'/login/wechat/'}>
        <LoginWechatLanding />
      </Route>
      {/* <Route key={'signup'} path={'/signup'} exact>
        <Signup />
      </Route> */}
      <Route key={'hk-login-wechat-launch'} exact path={'/hk-login-wechat-launch'}>
        <HKLoginWechatlaunch />
      </Route>
      <Route key={'fi-login-wechat-launch'} exact path={'/fi-login-wechat-launch'}>
        <FILoginWechatlaunch />
      </Route>
      <Route key={'signup-login'} exact path={['/signup', '/login']}>
        <MixedSignupLogin />
      </Route>
      <Route key={'nls-detail-mobile'} exact path={'/nls/:nlsID/mobile-detail'}>
        <NlsDetailFrontend />
      </Route>
      <Route key={'sr'} exact path={'/sr'}>
        <SR />
      </Route>
      <Route key={'404'}>
        <VmpNotfound />
      </Route>
    </Switch>
  )
}

export default Vmp
