import { AxiosInstanceFunc, IResponse } from '@hz-insights/web-components'

//个性化信息的类型
export interface IPersonalizedInfoBase {
  title: string
  theme: string
  logo: string
  header_background: string
  header_color: string
}
export interface IPersonalizedInfo extends IPersonalizedInfoBase {
  id?: number
  logo_link?: string
}
export interface IPersonalizedContext {
  personalizedInfo: IPersonalizedInfo | undefined
  loading: boolean
  getPersonalizedInfo: () => Promise<any>
}

export const PersonalizedService = {
  // 获取个性化信息
  getPersonalizedInfo: (): IResponse<IPersonalizedInfo> =>
    AxiosInstanceFunc({
      url: '/backend/homepage',
      method: 'get'
    }),
  //更新个性化信息
  putPersonalizedInfo: (data: IPersonalizedInfoBase): IResponse<IPersonalizedInfo> =>
    AxiosInstanceFunc({
      url: '/backend/homepage',
      method: 'put',
      data
    })
}
