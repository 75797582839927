import React, { useState, useEffect, useRef } from 'react'

import { Tag, Input, Space, InputRef } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

interface INoteTagProp {
  onTagsChange: (tags: string[]) => void
  initialTags: string[]
}

const NoteTag: React.FC<INoteTagProp> = ({ onTagsChange, initialTags }) => {
  const [tags, setTags] = useState<string[]>(initialTags)
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [editInputIndex, setEditInputIndex] = useState(-1)
  const [editInputValue, setEditInputValue] = useState('')

  const inputRef = useRef<InputRef>(null)
  const editInputRef = useRef<InputRef>(null)

  const handleClose = (removedTag: string) => {
    setTags(pre => pre.filter(tag => tag !== removedTag))
  }

  const showInput = () => {
    setInputVisible(true)
  }

  useEffect(() => {
    inputVisible && inputRef.current?.focus()
  }, [inputVisible])

  useEffect(() => {
    editInputIndex !== -1 && editInputRef.current?.focus()
  }, [editInputIndex])

  useEffect(() => {
    onTagsChange(tags)
  }, [tags, onTagsChange])

  const handleInputConfirm = () => {
    if (!tags.includes(inputValue) && inputValue) {
      setTags([...tags, inputValue])
    }
    setInputValue('')
    setInputVisible(false)
  }

  const handleEditInputConfirm = () => {
    if (editInputValue) {
      const newTags = [...tags]
      newTags[editInputIndex] = editInputValue
      setTags(newTags)
    }
    // setEditInputValue('')
    setEditInputIndex(-1)
  }

  return (
    <Space wrap={true}>
      {tags.map((tag, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              key={tag}
              size="small"
              ref={editInputRef}
              value={editInputValue}
              onChange={e => setEditInputValue(e.target.value)}
              onPressEnter={handleEditInputConfirm}
              onBlur={handleEditInputConfirm}
              style={{ maxWidth: '80px' }}
            ></Input>
          )
        } else {
          return (
            <Tag key={tag} closable={true} onClose={() => handleClose(tag)}>
              <span
                onDoubleClick={e => {
                  setEditInputIndex(index)
                  setEditInputValue(tag)
                  e.stopPropagation()
                }}
              >
                {tag}
              </span>
            </Tag>
          )
        }
      })}
      {inputVisible ? (
        <Input
          ref={inputRef}
          size="small"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
          style={{ maxWidth: '80px' }}
        ></Input>
      ) : (
        <Tag onClick={showInput}>
          <PlusOutlined /> 添加标签
        </Tag>
      )}
    </Space>
  )
}

export { NoteTag }
