import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { CompatibleRouteProvider } from '@hz-insights/web-components'

const CompatibleRouterProvider: React.FC = ({ children }) => {
  const history = useHistory()
  const { pathname } = useLocation()
  return (
    <CompatibleRouteProvider
      CompatibleRoute={{
        pathname,
        navigate(pathname, options) {
          history[options?.replace ? 'replace' : 'push'](pathname)
        }
      }}
    >
      {children}
    </CompatibleRouteProvider>
  )
}

export { CompatibleRouterProvider }
