import { AxiosInstanceFunc, IResponse } from '@hz-insights/web-components'

export enum Subject {
  Article = 'article',
  StockCoverage = 'stockCoverage',
  Chart = 'chart',
  Model = 'model',
  Upload = 'upload',
  Collection = 'collection',
  Meeting = 'meeting',
  User = 'user',
  Note = 'note',
  Admin = 'admin',
  Slide = 'slide',
  QAcommunity = 'qaCommunity',
  All = 'all'
}

export enum Action {
  Manage = 'manage',
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete'
}

export interface IPermission {
  id: number
  name: string
  description: string
  can: boolean
  subject: Subject
  action: Action
  field?: string
  condition?: string
}

export const CaslService = {
  getSelfPermission: (): IResponse<IPermission[]> => AxiosInstanceFunc({ url: '/user/self/permission', method: 'get' })
}
