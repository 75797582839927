import { AxiosInstanceFunc, IResponse } from '@hz-insights/web-components'

export interface IQAInfo {
  question: IQAInfoQuestion
  answers?: IQAInfoAnswer[]
}
export interface IQAInfoQuestion {
  id: number
  title: string
  description: string
  industry: IQAInfoQuestionTag
  field: IQAInfoQuestionTag
  stock: string[] | undefined
  author: IQAInfoAuthor
  create_date: string
  update_date: string
  is_handle: boolean
  op_status: 1 | 2 | 3
  mention_list: string[]
}
export interface IQAInfoQuestionTag {
  id: number
  name: string
}
export interface IQAInfoAuthor {
  id: string
  type: number
  name: string
}
export interface IQAInfoAnswer {
  id: number
  question_id: number
  content: string
  author: IQAInfoAuthor
  create_date: string
  update_date: string
}

export interface IQAMentionMeInfo {
  id: string
  question_id: number
  user_id: string
  user_type: number
  is_handle: boolean
  create_date: string
  update_date: string
}

export interface IHandleQuestionData {
  title: string
  description: string
  industry_id: number
  field_id: number
  stock: string[]
  mention_list: string[]
}
export interface IQuestionInfoExpand extends IHandleQuestionData {
  id: number
  user_id: string
  user_type: number
  is_active: boolean
  create_date: string
  update_date: string
}

export interface IAnswerInfoExpand {
  id: number
  question_id: string
  content_id: string
  user_id: string
  user_type: number
  is_active: boolean
  create_date: string
  update_date: string
}

export interface IGetQAInfoAllParams {
  keyword?: string
  author?: string
  mention?: string
  industry_id?: number
  field_id?: number
  respondent?: string
  all?: boolean
  stock?: string[]
  take?: number
  skip?: number
  mode?: 'self' | 'all' | 'mention'
  order?: string
  sort?: string
  isAnswer?: boolean
}

export interface ICreateAnswerData {
  question_id: number
  body: string
}
export const QAcommunityService = {
  getQAInfoAll: ({ take, skip, ...params }: IGetQAInfoAllParams): IResponse<IQAInfo[]> =>
    AxiosInstanceFunc({
      url: '/qa_community',
      method: 'get',
      params: {
        ...params,
        take: take,
        skip: !!skip && !!take ? take * (skip - 1) : 0
      }
    }),
  getQAInfo: (questionID: number): IResponse<IQAInfo> =>
    AxiosInstanceFunc({ url: `/qa_community/question/${questionID}`, method: 'get' }),
  getMentionMeCount: (): IResponse<{ count: number }> =>
    AxiosInstanceFunc({ url: '/qa_community/mention', method: 'get' }),
  updateMentionMeStatus: (questionID: number, data: { is_handle: boolean }): IResponse<IQAMentionMeInfo> =>
    AxiosInstanceFunc({ url: `/qa_community/question/status/${questionID}`, method: 'put', data }),
  createQuestion: (data: IHandleQuestionData): IResponse<IQuestionInfoExpand> =>
    AxiosInstanceFunc({ url: '/qa_community/question', method: 'post', data }),
  updateQuestion: (questionID: number, data: IHandleQuestionData): IResponse<IQuestionInfoExpand> =>
    AxiosInstanceFunc({ url: `/qa_community/question/${questionID}`, method: 'put', data }),
  deleteQuestion: (questionID: number): IResponse<IQuestionInfoExpand> =>
    AxiosInstanceFunc({ url: `/qa_community/question/${questionID}`, method: 'delete' }),
  createAnswer: (data: ICreateAnswerData): IResponse<IAnswerInfoExpand> =>
    AxiosInstanceFunc({ url: '/qa_community/answer', method: 'post', data }),
  updateAnswer: (answerID: number, data: { body: string }): IResponse<IQuestionInfoExpand> =>
    AxiosInstanceFunc({
      url: `/qa_community/answer/${answerID}`,
      method: 'put',
      data
    }),
  deleteAnswer: (answerID: number): IResponse<IQuestionInfoExpand> =>
    AxiosInstanceFunc({ url: `/qa_community/answer/${answerID}`, method: 'delete' })
}
