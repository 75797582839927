import { useState } from 'react'

import { useHistory, useRouteMatch, useLocation } from 'react-router-dom'

import { useUpdateEffect } from 'ahooks'

const useSearchParams = (
  initialSearchParams?: URLSearchParams
): [URLSearchParams, (newSearchParams: URLSearchParams) => void] => {
  const history = useHistory()
  const { search } = useLocation()
  const [searchParams, setSearchParams] = useState(
    initialSearchParams || new URLSearchParams(new URLSearchParams(search))
  )

  const { url } = useRouteMatch()

  useUpdateEffect(() => {
    history.replace(`${url}?${searchParams}`)
  }, [history, searchParams])

  return [searchParams, setSearchParams]
}

export { useSearchParams }
