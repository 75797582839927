import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useRequest from '@ahooksjs/use-request'

import { message } from 'antd'

import { AuthProvider as SubAuthProvider, setAxiosInstanceFuncAuthorizationHeader } from '@hz-insights/web-components'

import { AuthService, IUserInfo } from './Auth.service'
import { checkWechat } from '../utils/wxConfig'
import { ticketConfig } from 'utils/synchronizeTicket'

interface Login {
  (params: { phone_number: number; password: string; next: string; unionID?: string }): Promise<void>
}

interface Logout {
  (): void
}

interface LoginWechatLaunch {
  (param: { redirectURI: string }): void
}

interface LoginWechat {
  (param: { code: string; next: string }): Promise<unknown>
}

interface LoginEmail {
  (param: {
    email: string
    code: string
    next?: string
    article_id?: string
    disableNavigateFirstLogin?: boolean
  }): Promise<boolean>
}

interface LoginCaptcha {
  (param: {
    phone_number: number
    code: string
    next?: string
    unionID?: string
    article_id?: string
    disableNavigateFirstLogin?: boolean
  }): Promise<boolean>
}

const AuthContexts = React.createContext<
  | {
      jwt: string | undefined
      userInfo: IUserInfo | undefined
      isWechat: boolean
      login: Login
      logout: Logout
      loginWechatLaunch: LoginWechatLaunch
      loginWechat: LoginWechat
      loginEmail: LoginEmail
      loginCaptcha: LoginCaptcha
      getProfile: () => void
      getProfileLoading: boolean
      loginEnd: (access_token: string, next: string) => void
    }
  | undefined
>(undefined)

type ContextProviderProps = { children: React.ReactNode }

const AuthProvider: React.FC<ContextProviderProps> = ({ children }: ContextProviderProps) => {
  const history = useHistory()
  const searhParams = new URLSearchParams(useLocation().search)
  const auth_token = localStorage.getItem('token')
  // token初始值
  const [jwt, setJwt] = useState<string | undefined>(auth_token !== null ? auth_token : undefined)
  const [userInfo, setUserInfo] = useState<IUserInfo>()
  const isWechat = checkWechat()

  const clearAuth = () => {
    localStorage.removeItem('token')
    setJwt(undefined)
    setUserInfo(undefined)
  }

  const loginEnd = (access_token: string, next?: string) => {
    // message.success('登录成功')
    localStorage.setItem('token', access_token)
    ticketConfig.synchronizeLocalStorage2Cookie()
    setAxiosInstanceFuncAuthorizationHeader(access_token)
    setJwt(access_token)
    history.replace(next || '/')
  }

  const login: Login = async ({ phone_number, password, next, unionID }): Promise<void> =>
    await AuthService.login({ phone_number, password, unionID })
      .then(data => {
        loginEnd(data.data.data.access_token, next)
      })
      .catch(error => {
        message.error('账号或密码错误' + error)
      })
  // const register = () => authClient.register(form).then(reload)

  const logout: Logout = () => {
    localStorage.removeItem('token')
    ticketConfig.synchronizeLocalStorage2Cookie()
    setJwt(undefined)
    setUserInfo(undefined)
    setAxiosInstanceFuncAuthorizationHeader('')
    history.push('/')
  }

  const loginWechatLaunch: LoginWechatLaunch = ({ redirectURI }) => {
    if (isWechat) {
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
        process.env.REACT_APP_WECHAT_APPID
      }&redirect_uri=${encodeURIComponent(
        redirectURI
      )}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
    } else {
      window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${
        process.env.REACT_APP_WECHAT_OPEN_APPID
      }&redirect_uri=${encodeURIComponent(
        redirectURI
      )}&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect`
    }
  }
  const loginWechat: LoginWechat = async ({ code, next }) =>
    await AuthService.loginWechat({ isWechat, code })
      .then(data => {
        if (data.data.data.access_token) {
          loginEnd(data.data.data.access_token, next)
        } else {
          message.info('该微信号第一次授权登录平台，请先绑定手机号')
          history.push(`/login?uid=${data.data.data.unionID}&next=${next}`)
        }
      })
      .catch(error => message.error('微信登录错误'))
  const loginCaptcha = async ({
    next,
    disableNavigateFirstLogin,
    ...restParams
  }: {
    phone_number: number
    code: string
    next?: string
    unionID?: string
    article_id?: string
    disableNavigateFirstLogin?: boolean
  }) =>
    await AuthService.loginByCaptcha(restParams)
      .then(data => {
        if (data.data.code === 0) {
          loginEnd(
            data.data.data.access_token,
            data.data.data.first_login && disableNavigateFirstLogin
              ? `/signup?${(searhParams.append('entrance', 'sms'), searhParams)}`
              : next
          )
          return true
        } else {
          message.error(data.data.msg)
          return false
        }
      })
      .catch(error => {
        return false
      })

  const loginEmail: LoginEmail = async ({ email, code, next, article_id, disableNavigateFirstLogin }) => {
    const res = await AuthService.loginEmail({ email, code, article_id })
    if (res.data.code === 0) {
      loginEnd(
        res.data.data.access_token,
        res.data.data.first_login && disableNavigateFirstLogin
          ? `/signup?${(searhParams.append('entrance', 'email'), searhParams)}`
          : next
      )
      return true
    } else {
      message.error(res.data.msg)
      return false
    }
  }

  const { run: getProfile, loading: getProfileLoading } = useRequest(() => AuthService.getProfile({ token: jwt }), {
    manual: true,
    cacheKey: 'userinfo',
    formatResult: response => response.data.data,
    onSuccess: data => {
      setUserInfo(data)
    },
    onError: (e: any) => {
      console.log(e)
      if (!jwt) {
        console.log('尚未登录，您现在为游客身份～')
      } else if (e?.response?.status === 401) {
        clearAuth()
        message.warning('您的试用账号已过期, 如您已转为正式账号，请登录正式账号')
        history.push('/login')
      }
    }
  })

  useEffect(() => {
    jwt && getProfile()
  }, [jwt, getProfile])

  return (
    <AuthContexts.Provider
      value={{
        jwt,
        userInfo,
        isWechat,
        login,
        logout,
        loginWechatLaunch,
        loginWechat,
        loginCaptcha,
        loginEmail,
        getProfile,
        getProfileLoading,
        loginEnd
      }}
    >
      <SubAuthProvider auth={{ jwt, userInfo }}>{children}</SubAuthProvider>
    </AuthContexts.Provider>
  )
}

const useAuth = () => {
  const context = React.useContext(AuthContexts)
  if (context === undefined) {
    throw new Error('useContext must be used within a ContextProvider')
  }
  return context
}

export { AuthProvider, useAuth }
