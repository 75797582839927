import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react'

import useRequest from '@ahooksjs/use-request'
import dayjs from 'dayjs'
import classNames from 'classnames'

import { Button, Form, Space, Input, Tree, message, Tag, Empty, Spin, Tooltip } from 'antd'
import { MenuUnfoldOutlined, MenuFoldOutlined, PlusOutlined } from '@ant-design/icons'

import { NoteService, INoteTreeNode } from './Note.service'
import { NoteNode } from './NoteNode'
import { NoteTag } from './NoteTag'
import { useNote } from './NoteContext'

import style from './css/Note.module.scss'

interface NoteProps {
  style?: React.CSSProperties
}

const FroalaWrapper = React.lazy(() => import('../components/FroalaWrapper'))

//按标签搜索笔记
const searchTree = (noteTree: INoteTreeNode[], searchKey: string) => {
  const result: INoteTreeNode[] = []
  if (!searchKey) {
    return result
  }
  const traversal = (list: INoteTreeNode[]) => {
    list.forEach(item => {
      item.tag_info?.includes(searchKey) && result.push(item)
      if (item.children) {
        traversal(item.children)
      }
    })
  }
  traversal(noteTree)
  return result
}

//根据ID查找树中节点
const findNodeInTreeByID = (nodeList: INoteTreeNode[], nodeID: number): null | INoteTreeNode => {
  let result: INoteTreeNode | null = null
  const findNodeInTreeByIDImplement = (nodeList: INoteTreeNode[], nodeID: number) => {
    nodeList.forEach(item => {
      if (item.id === nodeID) {
        result = item
        return
      }
      if (item.children) {
        findNodeInTreeByIDImplement(item.children, nodeID)
      }
    })
  }
  findNodeInTreeByIDImplement(nodeList, nodeID)
  return result
}

const Note: React.FC<NoteProps> = ({ style: propStyle, children, ...restProps }) => {
  const {
    noteContextState: { expandedKeys, selectedKeys, noteID, searchKey },
    setNoteContextState,
    addToFroalaRef
  } = useNote()

  //笔记的阅读、编辑模式
  const [isEdit, setIsEdit] = useState(false)

  const [collapsed, setCollapsed] = useState(false)

  const [froalaValue, setFroalaValue] = useState('')

  const [noteInfo, setNoteInfo] = useState<{
    title: string
    tagInfo: string | null
    content: string | null
    updateDate: string
  }>()

  //接口获取的tree
  const [treeData, setTreeData] = useState<INoteTreeNode[]>([])

  const froalaInstanceRef = useRef<any>(null)

  const editedTags = useRef<string[]>()

  const [form] = Form.useForm()

  const addToFroala = useCallback(
    ({ addedText, url, urlTitle }: { addedText: string; url: string; urlTitle?: string }) => {
      if (!noteID) {
        return
      }
      const insertedContent = `<blockquote>${addedText}<br><a href=${url} target='_blank'>${
        urlTitle || url
      }</a></blockquote>`
      if (isEdit) {
        if (froalaInstanceRef.current) {
          froalaInstanceRef.current.selection.restore()
          froalaInstanceRef.current.html.insert(insertedContent, false)
        }
      } else {
        setIsEdit(true)
        form.setFieldsValue({
          ...noteInfo,
          content: (noteInfo?.content || '') + insertedContent
        })
        setFroalaValue((noteInfo?.content || '') + insertedContent)
      }
    },
    [form, isEdit, noteID, noteInfo]
  )

  useEffect(() => {
    addToFroalaRef.current = addToFroala
    return () => {
      addToFroalaRef.current = null
    }
  }, [addToFroala, addToFroalaRef])

  //获取tree
  const { run: getNoteTree, loading: getNoteTreeLoading } = useRequest(NoteService.getNoteTree, {
    manual: true,
    onSuccess: result => {
      setTreeData(result.data.data)
    }
  })

  //获取笔记详情
  const { run: getNoteDetail, loading: getNoteDetailLoading } = useRequest(NoteService.getNoteNodeInfo, {
    manual: true,
    onSuccess: result => {
      const { title, tag_info, content, update_date } = result.data.data
      setNoteInfo({ title, tagInfo: tag_info, content: content?.body as string, updateDate: update_date })
    }
  })

  //获取最近更新的笔记
  const { run: getLatestNote, loading: getLatestNoteLoading } = useRequest(NoteService.getLatestNote, {
    manual: true,
    onSuccess: result => {
      const { title, tag_info, content, update_date, id } = result.data.data
      setNoteInfo({ title, tagInfo: tag_info, content: content?.body as string, updateDate: update_date })
      setNoteContextState(pre => ({ ...pre, noteID: id, selectedKeys: [String(id)] }))
    }
  })

  //更新节点
  const { run: updateNoteNode, loading: updateNoteNodeLoading } = useRequest(NoteService.updateNoteNode, {
    manual: true,
    onSuccess: (result, [id]) => {
      if (result.data.code === 0) {
        message.success('更新成功')
        //被更新节点为当前展示的笔记时, 重新获取笔记详情, 并切换为阅读状态
        if (id === noteID) {
          setIsEdit(false)
          getNoteDetail(noteID as number)
        }
        getNoteTree()
      } else {
        message.error(result.data.msg)
      }
    }
  })

  //创建节点
  const { run: createNoteNode, loading: createNoteNodeLoading } = useRequest(NoteService.createNoteNode, {
    manual: true,
    onSuccess: (result, [{ title, type, parent }]) => {
      setNoteContextState(pre => ({
        ...pre,
        expandedKeys: [...expandedKeys, String(parent as number)],
        selectedKeys: [String(result.data.data.id)],
        noteID: result.data.data.type === 2 ? result.data.data.id : pre.noteID
      }))
      getNoteTree()
    }
  })

  //检查删除的节点是否包含当前选中的笔记
  const checkIsNoteIDIndeletedNode = (deletedID: number): boolean => {
    const deletedNode = findNodeInTreeByID(treeData, deletedID)
    if (deletedNode) {
      return !!findNodeInTreeByID([deletedNode], noteID as number)
    } else {
      return false
    }
  }

  //删除节点
  const { run: deleteNoteNode, loading: deleteNoteNodeLoading } = useRequest(NoteService.deleteNoteNode, {
    manual: true,
    onSuccess: (result, [id]) => {
      if (result.data.code === 0) {
        message.success('删除成功')
        checkIsNoteIDIndeletedNode(id) && getLatestNote()
        getNoteTree()
      }
    }
  })

  //构造Tree的子节点
  const generateTreeChildren = (treeNodeList: INoteTreeNode[]): ReactElement[] => {
    return treeNodeList.map(item => {
      if (item.children) {
        return (
          <Tree.TreeNode
            title={
              <NoteNode
                treeNode={item}
                selected={String(item.id) === selectedKeys[0]}
                updateNoteNode={updateNoteNode}
                createNoteNode={createNoteNode}
                deleteNoteNode={deleteNoteNode}
              />
            }
            isLeaf={item.type === 2}
            key={String(item.id)}
          >
            {generateTreeChildren(item.children)}
          </Tree.TreeNode>
        )
      } else {
        return (
          <Tree.TreeNode
            title={
              <NoteNode
                treeNode={item}
                selected={String(item.id) === selectedKeys[0]}
                updateNoteNode={updateNoteNode}
                createNoteNode={createNoteNode}
                deleteNoteNode={deleteNoteNode}
              />
            }
            isLeaf={item.type === 2}
            key={String(item.id)}
          />
        )
      }
    })
  }

  useEffect(() => {
    getNoteTree()
  }, [getNoteTree])

  useEffect(() => {
    noteID && getNoteDetail(noteID)
  }, [noteID, getNoteDetail])

  useEffect(() => {
    setIsEdit(false)
  }, [noteID])

  useEffect(() => {
    if (!noteID) {
      getLatestNote()
    }
  }, [getLatestNote, noteID])

  const onTagsChange = useCallback(
    (tags: string[]) => {
      editedTags.current = tags
    },
    [editedTags]
  )

  const saveNote = () => {
    const { title } = form.getFieldsValue(['title'])
    const tagInfo = (editedTags.current as string[]).join(';')
    const body = froalaInstanceRef.current?.html.get()
    updateNoteNode(noteID as number, {
      title: title === noteInfo?.title ? undefined : title,
      tag_info: tagInfo === noteInfo?.tagInfo ? undefined : tagInfo,
      body: body === noteInfo?.content ? undefined : body
    })
  }

  return (
    <div className={style['note-wrap']}>
      <Spin
        spinning={
          getNoteTreeLoading ||
          getNoteDetailLoading ||
          updateNoteNodeLoading ||
          createNoteNodeLoading ||
          deleteNoteNodeLoading ||
          getLatestNoteLoading
        }
      >
        <div className={style['note']} style={propStyle}>
          <div className={classNames(style['contents-tree'], { [style['hidden']]: collapsed })}>
            <Space style={{ height: '32px' }}>
              <Input.Search
                placeholder="搜索标签"
                size="small"
                value={searchKey}
                onSearch={value => {
                  setNoteContextState(pre => ({ ...pre, searchKey: value }))
                }}
                onChange={e => {
                  setNoteContextState(pre => ({ ...pre, searchKey: e.target.value }))
                }}
              />
              <Tooltip title={'收起目录'} destroyTooltipOnHide={true} trigger={['hover', 'click']}>
                <span
                  onClick={() => {
                    setCollapsed(true)
                  }}
                  className={style['contents-top-icon']}
                >
                  <MenuFoldOutlined />
                </span>
              </Tooltip>
              <Tooltip title={'新建文件夹'} destroyTooltipOnHide={true} trigger={['hover', 'click']}>
                <span
                  className={style['contents-top-icon']}
                  onClick={() => {
                    createNoteNode({ type: 1, parent: null, title: '新建文件夹' })
                  }}
                >
                  <PlusOutlined />
                </span>
              </Tooltip>
            </Space>

            <Tree
              draggable={true}
              allowDrop={({ dropNode, dropPosition }) => {
                return !(dropNode.isLeaf as boolean)
              }}
              onDrop={({ node, dragNode, dropToGap }) => {
                dropToGap
                  ? updateNoteNode(Number(dragNode.key), { brother: Number(node.key) })
                  : updateNoteNode(Number(dragNode.key), { parent: Number(node.key) })
              }}
              expandedKeys={[...expandedKeys]}
              onExpand={expandedKeys => {
                setNoteContextState(pre => ({ ...pre, expandedKeys: expandedKeys as string[] }))
              }}
              showIcon
              showLine={{ showLeafIcon: true }}
              selectedKeys={[...selectedKeys]}
              style={{ marginTop: '16px', display: searchKey ? 'none' : 'block' }}
              onSelect={([key], e) => {
                if (!e.selected) {
                  return
                }
                if (e.node.isLeaf) {
                  //选中笔记类型的节点时, 需更新 noteID
                  setNoteContextState(pre => ({ ...pre, selectedKeys: [key as string], noteID: Number(key as string) }))
                } else {
                  setNoteContextState(pre => ({ ...pre, selectedKeys: [key as string] }))
                }
              }}
            >
              {generateTreeChildren(treeData)}
            </Tree>

            <div style={{ marginTop: '16px' }}>
              {searchKey &&
                (() => {
                  const list = searchTree(treeData, searchKey)
                  return list.length === 0 ? (
                    <Empty description="没有相关笔记~" />
                  ) : (
                    list.map(item => (
                      <div
                        key={item.id}
                        onClick={() => {
                          setNoteContextState(pre => ({
                            ...pre,
                            selectedKeys: [String(item.id as number)],
                            noteID: item.id
                          }))
                        }}
                        style={{ marginTop: '10px' }}
                      >
                        <NoteNode
                          treeNode={item}
                          selected={String(item.id) === selectedKeys[0]}
                          updateNoteNode={updateNoteNode}
                          createNoteNode={createNoteNode}
                          deleteNoteNode={deleteNoteNode}
                        />
                      </div>
                    ))
                  )
                })()}
            </div>
          </div>
          <div className={style['note-detail']}>
            {collapsed && (
              <Tooltip title="打开目录" trigger={['hover', 'click']}>
                <span
                  // size="small"
                  onClick={() => {
                    setCollapsed(false)
                  }}
                  className={style['open-contents-btn']}
                >
                  <MenuUnfoldOutlined />
                </span>
              </Tooltip>
            )}
            {noteID ? (
              isEdit ? (
                <div className={style['edit-mode']}>
                  <Form form={form} onFinish={saveNote}>
                    <div className={style['edit-mode']}>
                      <div className={style['title-edit-and-btns']}>
                        <Form.Item
                          label="标题"
                          name="title"
                          wrapperCol={{ span: 24 }}
                          rules={[{ required: true, message: '请输入标题！' }]}
                          style={{ width: '50%' }}
                        >
                          <Input placeholder="请输入标题" size="small"></Input>
                        </Form.Item>
                        <Space className={style['edit-mode-buttons']}>
                          <Button type="primary" htmlType="submit">
                            保存
                          </Button>
                          <Button
                            type="primary"
                            onClick={() => {
                              setIsEdit(false)
                            }}
                          >
                            取消
                          </Button>
                        </Space>
                      </div>

                      <div className={style['tag-edit']}>
                        <span className={style['tag-edit-title']}>标签</span>
                        {noteInfo && (
                          <NoteTag
                            onTagsChange={onTagsChange}
                            initialTags={noteInfo.tagInfo ? noteInfo.tagInfo.split(';') : []}
                          />
                        )}
                      </div>
                      <div className={style['editor']}>
                        <FroalaWrapper
                          handleUpstream={editorInstance => {
                            froalaInstanceRef.current = editorInstance
                          }}
                          value={froalaValue}
                          configType="note"
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              ) : (
                <div className={style['read-mode']}>
                  <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                    <h3 style={{ fontWeight: 600, margin: 0 }}>{noteInfo?.title}</h3>
                    <Button
                      type="primary"
                      className={style['edit-button']}
                      onClick={() => {
                        setIsEdit(true)
                        form.setFieldsValue({ ...noteInfo })
                        setFroalaValue(noteInfo?.content || '')
                      }}
                    >
                      编辑
                    </Button>
                  </Space>
                  <p className={style['latest-edit-info']}>
                    最后编辑于: {dayjs(noteInfo?.updateDate).format('YYYY-MM-DD HH:mm:ss')}
                  </p>
                  {noteInfo && noteInfo.tagInfo && (
                    <Space wrap style={{ paddingBottom: '10px' }}>
                      {noteInfo.tagInfo.split(';').map((item, index) => (
                        <Tag color={index % 2 === 0 ? 'orange' : 'purple'} key={index}>
                          {item}
                        </Tag>
                      ))}
                    </Space>
                  )}
                  {noteInfo ? (
                    !noteInfo.content ? (
                      <Empty description="暂无内容~" style={{ width: '100%' }} />
                    ) : (
                      <div
                        className={style['note-html']}
                        dangerouslySetInnerHTML={{ __html: noteInfo.content }}
                        style={{ marginTop: '10px' }}
                      ></div>
                    )
                  ) : null}
                </div>
              )
            ) : null}
          </div>
        </div>
      </Spin>
    </div>
  )
}

export { Note }
