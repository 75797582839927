import { AxiosInstanceFunc, IResponse } from '@hz-insights/web-components'
import { JwtPayload } from 'jwt-decode'

export interface IUserInfo {
  name: string
  phone_number: string
  avatar: string
  nickname: string
  company: string
  group_id: number
  role_id: number
  role?: 'guest' | 'admin' | 'user' | undefined
  rule?: string[]
  invite: number
}

//JWT 泛型声明
export interface IJwtPayload extends JwtPayload {
  phone_number: number
  name: string
}

export const AuthService = {
  login: (params: { phone_number: number; password: string; unionID?: string }): IResponse<{ access_token: string }> =>
    AxiosInstanceFunc({
      url: '/auth/login',
      method: 'post',
      params
    }),
  loginWechat: ({ isWechat, code }: { isWechat: boolean; code: string }): IResponse<any> =>
    AxiosInstanceFunc({
      url: '/auth/login/wechat',
      method: 'post',
      params: { is_wechat: Number(isWechat), code }
    }),
  getProfile: ({ token }: { token: string | undefined }): IResponse<IUserInfo> =>
    AxiosInstanceFunc({
      url: '/auth/profile',
      method: 'get',
      headers: { Authorization: `bearer ${token}` }
    }),
  loginByCaptcha: (params: {
    phone_number: number
    code: string
    unionID?: string
    article_id?: string
  }): IResponse<{ access_token: string; phone_number?: string; first_login?: 0 | 1 }> =>
    AxiosInstanceFunc({
      url: 'auth/login/captcha',
      method: 'post',
      params
    }),
  loginEmail: ({
    code,
    email,
    article_id
  }: {
    code: string
    email: string
    article_id?: string
  }): IResponse<{ access_token: string; first_login: boolean }> =>
    AxiosInstanceFunc({ url: '/auth/login/email', method: 'post', data: { code, email, article_id } }),
  getCaptcha: (params: { phone_number: number; area_code: number }): IResponse<any> =>
    AxiosInstanceFunc({
      url: 'auth/login/captcha',
      method: 'get',
      params
    }),
  getEmailCode: ({ email }: { email: string }): IResponse<string> =>
    AxiosInstanceFunc({ url: '/auth/login/email', method: 'get', params: { email } })
}
