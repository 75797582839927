import { AxiosInstanceFunc, IResponse } from '@hz-insights/web-components'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import wx from 'weixin-js-sdk'
import Config from '../config'

const baseTitle = `${Config.titleGenerateHz}${Config.titleGenerateYj}`
const baseImgUrl = 'http://vmp.hzinsights.com/static/img/hzicon.ico'

export const checkWechat = (): boolean => /MicroMessenger/i.test(window.navigator.userAgent)

export interface IJssign {
  url: string
  noncestr: string
  timestamp: number
  signature: string
}

export interface IShareMsg {
  url: string
  title: string
  imgurl: string | undefined
  desc: string
}

const getJssign = (url: string): IResponse<IJssign> =>
  AxiosInstanceFunc({
    url: `/auth/jssign`,
    method: 'get',
    params: {
      url: url
    }
  })

/**
 * 分享信息配置
 * @param shareMsg 分享信息对象
 */
export const shareConfig = (shareMsg: IShareMsg): void => {
  getJssign(shareMsg.url).then(result => {
    wx.config({
      debug: false,
      appId: 'wx783118ccac2e9fd3',
      timestamp: result.data.data.timestamp,
      nonceStr: result.data.data.noncestr,
      signature: result.data.data.signature,
      jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
    })
  })
  wx.ready(() => {
    wx.updateAppMessageShareData({
      title: shareMsg.title ? shareMsg.title : baseTitle,
      desc: shareMsg.desc ? shareMsg.desc : '', // 分享描述
      link: shareMsg.url,
      imgUrl: shareMsg.imgurl || baseImgUrl
    })

    wx.updateTimelineShareData({
      title: shareMsg.title ? shareMsg.title : baseTitle,
      link: shareMsg.url,
      imgUrl: shareMsg.imgurl || baseImgUrl
    })
  })
}
/**
 * 设置调用微信分享函数
 * @param title 标题
 * @param desc  描述
 * @param imgurl   小图
 */
export const setWxShare = (title: string, desc: string, imgurl: string | undefined): void => {
  const shareMsg = {
    url: window.location.href.split('#')[0],
    desc: desc,
    title: title,
    imgurl: imgurl
  }
  shareConfig(shareMsg)
}

export const prepareForWechatTag = async () => {
  const result = await getJssign(window.location.href.split('#')[0])
  wx.config({
    debug: true,
    appId: 'wx783118ccac2e9fd3',
    timestamp: result.data.data.timestamp,
    nonceStr: result.data.data.noncestr,
    signature: result.data.data.signature,
    jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'],
    openTagList: ['wx-open-audio']
  })
  await new Promise<void>((res, rej) => {
    wx.ready((...params: any[]) => {
      console.log(params)
      res()
    })
    wx.error((...params: any[]) => {
      console.log(params)
      rej()
    })
  })
}
