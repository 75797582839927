const DOMAIN = process.env.NODE_ENV === 'development' ? window.location.hostname : '.hzinsights.com'

const ticketConfig = {
  synchronizeLocalStorage2Cookie() {
    const localstorageJWT = localStorage.getItem('token')
    const expiresDate = new Date()
    expiresDate.setDate(expiresDate.getDate() + (localstorageJWT ? 3650 : -3650))
    document.cookie = `token=${localstorageJWT}; path=/; expires=${expiresDate.toUTCString()}; domain=${DOMAIN}`
  },

  synchronizeCookie2LocalStorage() {
    const cookieJwt =
      document.cookie
        .split(';')
        .map(item => item.trim())
        .find(item => /^token=/.test(item))
        ?.split('=')[1] ?? ''
    cookieJwt ? localStorage.setItem('token', cookieJwt) : localStorage.removeItem('token')
  }
}

export { ticketConfig }
