import React, { useRef, useEffect, createContext, useContext } from 'react'
import { useState } from 'react'
import classNames from 'classnames'

import { Tooltip } from 'antd'

import styles from './css/ColResize.module.scss'
interface IColResizeProp extends React.HTMLAttributes<HTMLDivElement> {
  initialLeftPercent: number
  children: React.ReactNodeArray
}

const ColResizeContext = createContext<{ colResizeLeftWidth?: number }>({})

const ColResize: React.FC<IColResizeProp> = ({ initialLeftPercent, children, ...restProps }) => {
  const { className: resizeClassName } = restProps
  const resizeRef = useRef<HTMLDivElement>(null)
  const [resizeLeftWidthPercent, setResizeLeftWidthPercent] = useState<number>(initialLeftPercent)
  const [isShowMantle, setIsShowMantle] = useState<boolean>(false)

  const moveStartXRef = useRef<number>()
  const moveStartLeftWidthRef = useRef<number>()
  const movePreviousXRef = useRef<number>()

  useEffect(() => {
    setResizeLeftWidthPercent(initialLeftPercent)
  }, [initialLeftPercent])

  const dividerOnMouseDown = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setIsShowMantle(true)
    //鼠标开始移动时 x 坐标
    moveStartXRef.current = e.clientX
    //鼠标开始移动时左侧区域的宽度百分比
    moveStartLeftWidthRef.current = resizeLeftWidthPercent
  }

  const mantleOnMouseUp = () => {
    setIsShowMantle(false)
  }

  const mantleOnMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const offsetX = e.clientX - (moveStartXRef.current as number)
    if (movePreviousXRef.current && resizeLeftWidthPercent < 30 && e.clientX < movePreviousXRef.current) {
      return
    }
    if (movePreviousXRef.current && resizeLeftWidthPercent > 70 && e.clientX > movePreviousXRef.current) {
      return
    }
    setResizeLeftWidthPercent(
      (moveStartLeftWidthRef.current as number) +
        (offsetX / parseFloat(window.getComputedStyle(resizeRef.current as HTMLDivElement).width)) * 100
    )
    movePreviousXRef.current = e.clientX
  }

  return (
    <ColResizeContext.Provider
      value={{
        colResizeLeftWidth: resizeRef.current
          ? parseInt(getComputedStyle(resizeRef.current).width) * resizeLeftWidthPercent * 0.01
          : undefined
      }}
    >
      <div ref={resizeRef} className={classNames(styles['resize-area'], resizeClassName)}>
        <div
          className={classNames(styles['resize-left'], { [styles['has-mantle']]: isShowMantle })}
          id="main-content"
          style={{ width: `${resizeLeftWidthPercent}%` }}
        >
          {children[0]}
        </div>
        {children[1] && (
          <Tooltip
            title={'按住鼠标左右滑动调整笔记区域宽度'}
            placement="right"
            overlayInnerStyle={{ maxWidth: '10em' }}
          >
            <div className={styles['resize-divider']} onMouseDown={dividerOnMouseDown}></div>
          </Tooltip>
        )}
        {children[1] && <div className={styles['resize-right']}>{children[1]}</div>}
        {isShowMantle && (
          <div
            className={styles['mantle']}
            style={{
              cursor: resizeLeftWidthPercent < 30 ? 'e-resize' : resizeLeftWidthPercent > 70 ? 'w-resize' : 'col-resize'
            }}
            onMouseUp={mantleOnMouseUp}
            onMouseMove={mantleOnMouseMove}
          ></div>
        )}
      </div>
    </ColResizeContext.Provider>
  )
}

const useColResize = () => {
  return useContext(ColResizeContext)
}

export { ColResize, useColResize }
