import React from 'react'
import useRequest from '@ahooksjs/use-request/es'

import { ConfigProvider } from 'antd'

import { PersonalizedService, IPersonalizedContext } from './Personalized.service'

// 创建context
export const PersonalizedContexts = React.createContext<IPersonalizedContext | undefined>(undefined)

//provider参数类型
type ContextProviderProps = { children: React.ReactNode }
//provider
const PersonalizedProvider: React.FC<ContextProviderProps> = ({ children }: ContextProviderProps) => {
  // 获取个性化信息
  const { data: personalizedInfo, loading: personalizedInfoLoading, run: getPersonalizedInfo } = useRequest(
    PersonalizedService.getPersonalizedInfo,
    {
      formatResult: response => response.data.data,
      onSuccess: result => undefined,
      onError: error => console.log(error)
    }
  )
  return personalizedInfo ? (
    <PersonalizedContexts.Provider
      value={{
        personalizedInfo: personalizedInfo,
        loading: personalizedInfoLoading,
        getPersonalizedInfo: getPersonalizedInfo
      }}
    >
      <ConfigProvider
        theme={
          personalizedInfo
            ? {
                token: { colorPrimary: personalizedInfo.theme }
              }
            : undefined
        }
      >
        {children}
      </ConfigProvider>
    </PersonalizedContexts.Provider>
  ) : (
    <></>
  )
}

const usePersonalized = (): IPersonalizedContext => {
  const context: IPersonalizedContext | undefined = React.useContext(PersonalizedContexts)
  if (context === undefined) {
    throw new Error('useContext must be used within a ContextProvider ~')
  }
  return context
}

export { PersonalizedProvider, usePersonalized }
