import { AxiosInstanceFunc, IResponse } from '@hz-insights/web-components'

export interface ICreateNoteNodeParam {
  parent?: number | null
  type: number
  title?: string
  tag_info?: string
  body?: string
}

export interface IUpdateNoteNodeParam {
  parent?: number
  brother?: number
  title?: string
  tag_info?: string
  body?: string
}

export interface INoteTreeNode {
  id: number
  note_phone_number: string
  type: number //1:目录; 2:笔记
  title: string
  tag_info: string | null
  create_date: string
  update_date: string
  children?: INoteTreeNode[]
}

export interface ICreateNoteNodeInfo {
  id: number
  note_phone_number: number
  parent: number | null
  type: number //1:目录; 2:笔记
  title: string
  tag_info: string
  content_id?: number
  create_date: string
  update_date: string
}

export interface INoteNodeInfo {
  id: number
  note_phone_number: string
  parent: number
  type: number
  title: string
  tag_info: string
  content_id: number
  create_date: string
  update_date: string
  content?: {
    id: number
    body: string
  }
}

export const NoteService = {
  getNoteTree: (): IResponse<INoteTreeNode[]> =>
    AxiosInstanceFunc({
      url: `/note`,
      method: 'get'
    }),

  createNoteNode: ({ parent, type, title, tag_info, body }: ICreateNoteNodeParam): IResponse<ICreateNoteNodeInfo> =>
    AxiosInstanceFunc({
      url: `/note`,
      method: 'post',
      data: { parent, type, title, tag_info, body }
    }),

  getNoteNodeInfo: (id: number): IResponse<INoteNodeInfo> =>
    AxiosInstanceFunc({
      url: `/note/${id}`,
      method: 'get'
    }),

  updateNoteNode: (id: number, { parent, brother, title, tag_info, body }: IUpdateNoteNodeParam): IResponse<void> =>
    AxiosInstanceFunc({
      url: `/note/${id}`,
      method: 'put',
      data: { parent, brother, title, tag_info, body }
    }),

  deleteNoteNode: (id: number): IResponse<void> =>
    AxiosInstanceFunc({
      url: `/note/${id}`,
      method: 'delete'
    }),

  getLatestNote: (): IResponse<INoteNodeInfo> =>
    AxiosInstanceFunc({
      url: '/note/latest',
      method: 'get'
    })
}
