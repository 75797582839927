import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// 路由变化时自动滚动至顶部
export default function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}
