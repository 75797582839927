import { AxiosInstanceFunc, IResponse, IPaginationParams } from '@hz-insights/web-components'
import { IArticleClicks } from 'User/Backend.service'

export interface IUser {
  phone_number: number
  name: string
}

export interface ITypeChartCreator {
  title?: string
  category?: string
  description?: string
  type?: string
  template_id?: number
  derive_id?: number
  ctag1_id?: number
  ctag_id?: number
  ptag1_id?: number
  ptag_id?: number
}

export interface ITagInfo {
  id: number
  description: string
  name: string
}

export enum Engine {
  Highcharts = 'highcharts',
  ECharts = 'echarts',
  Tabel = 'table'
}

export interface ITypeChartBase {
  update_date?: Date
  id: number
  title: string
  title_en?: string
  description: string
  publish_status?: number
  is_active: boolean
  js_code: string
  js_form?: any
  js_mode: number
  python_code: string
  transport_code?: string
  type: Engine
  cover?: string
  ptag_id?: number
  ptag1_id?: number
  ctag_id?: number
  ctag1_id?: number
  access_level?: number
  source?: string
  python_codeblock?: string[]
}

export interface IChartReview {
  id: number
  chart_id: number
  phone_number: string
  review: string
  is_active: boolean
  name: string
  update_date: string
}

export interface ITypeChart extends ITypeChartBase {
  author?: IUser
  author_phone_number?: string
  category: string
  // dtsetting: TypeDTSetting[]
  // kvsetting: TypeKVSetting[]
  // infoTmplsetting: TypeInfoTmplsetting[]
  //ChartsMP暂时
  ptag?: ITagInfo
  ctag?: ITagInfo
  ptag1?: ITagInfo
  ctag1?: ITagInfo
  setting: ISingleSetting[]
  is_download: number
  use_cache?: number
  co_author?: { name?: string; phone_number?: string }[]
  correlation_chart_id?: number
  chart_review: IChartReview[]
}
export interface IChartTemplInfoAll extends ITypeChartBase {
  create_date?: string
  author_phone_number?: string
  setting: {
    type?: string
    endDT?: string
    maxDT?: string
    minDT?: string
    startDT?: string
    varname?: string
    description?: string
  }
}
export interface IOldTypeChart {
  element_id: number
  element__title: string
  element__title_en: string
  image: string
}

export interface ITypeChartSeries {
  [propName: string]: any
}

export enum SettingType {
  DT = 'dt',
  SingleDT = 'singleDT',
  KV = 'kv',
  Tmpl = 'tmpl',
  Input = 'input'
}

export interface ISingleSettingBase {
  type: SettingType
  varname: string
  description: string
}

export interface IDTSetting extends ISingleSettingBase {
  startDT: string
  endDT: string
  minDT: string
  maxDT: string
}

export interface ISingleDTSetting extends ISingleSettingBase {
  singleDT: string
}

export interface IKVSetting extends ISingleSettingBase {
  stype: 'single' | 'multiple'
  defaultkey: string
  key: string[]
  value: string[]
}

export interface ITmplSetting extends ISingleSettingBase {
  defaultkey: string
  tmplsetting_id: number
}

export interface IInputSetting extends ISingleSettingBase {
  default: string
}

export type ISingleSetting = IDTSetting & ISingleDTSetting & IKVSetting & ITmplSetting & IInputSetting

export interface ITypeDTSettingBase {
  varName: string
  description: string
  startDT: string
  endDT: string
  minDT: string
  maxDT: string
}

export interface ITypeDTSetting extends ITypeDTSettingBase {
  id: number
  chart_id: string
}

export interface ITypeKVSettingBase {
  varname: string
  description: string
  defaultkey: string
  key: string[]
  value: string[]
}

export interface ITypeKVSetting extends ITypeKVSettingBase {
  id: number
  chart_id: string
}

export interface ITypeChartSaver {
  title: string
  category: string
  description: string
  is_active: boolean
  js_code: string
  js_form?: any
  python_code: string
  transport_code?: string
  publish_status?: number
  dtsetting: ITypeDTSettingBase[]
  kvsetting: ITypeKVSettingBase[]
  ptag_id?: number
  ptag1_id?: number
  ctag_id?: number
  ctag1_id?: number
  access_level?: number
  source?: string
  is_download?: number
  use_cache?: number
  co_author?: string[]
  correlation_chart_id?: number
}

export interface ITypeInfoTmplsetting {
  id: string
  varname: string
  tmplsetting_id: number
  description: string
  defaultkey: string
  chart_id: number
}

export interface ITypeTmplsetting {
  id: number
  name: string
  python_code: string
  option_type: number
}

export interface ITypeTmplsetting {
  name: string
}

export interface ICascader {
  label: string
  value: string
  children: ICascader[]
}

export interface ITypeTmplsettingSeries extends ITypeTmplsetting {
  data: string[] | ICascader[]
}

export interface ITypeTmplsettingSeriesCascader extends ITypeTmplsettingSeries {
  data: ICascader[]
}

export interface ICollectedChart {
  id: number
  phone_number: string
  chart_id: number
  add_favorites_time: string
  order: number
  chart_info: {
    title: string
    title_en: string
  }
}

export interface IChartCtag {
  id: number
  name: string
  description: string
  ptag_id: number
}

export interface IChartPtag {
  id: number
  name: string
  description: string
  ctag: IChartCtag[]
}

export interface IChartCodeBlockInfo {
  id: string
  name: string
  description: string
  code: string
  author_phone_numer: string
  create_date: string
  update_date: string
  is_reliable: boolean
  is_active: boolean
}

interface IChartCodeBlockData {
  name: string
  description: string
  code: string
  is_reliable: boolean
}

export interface IInsertData {
  trade_code: string
  sec_name: string
  unit: string
  remark: string
  frequency: string
}
export interface IGetChartsParams extends IPaginationParams {
  title?: string
  title_en?: string
  publish_status?: number
  mode?: string
  search_keywords?: string
  ptag_id?: number
  ctag_id?: number
}

export interface IGenerateChartSeriesDatas {
  setting?: string
  python_code?: string
  use_cache?: number
  python_codeblock?: string[]
}

export interface IGetChartSeriesBySQLDatas {
  sql?: string
  use_cache?: boolean
}

export interface ICollectChartDatas {
  chart_id?: number
  order?: number
}

export interface IUserBehaviorMonitorDatas {
  app_module: 'charts' | 'page' | 'search' | 'articles'
  action_type: 'GET' | 'CLICK' // GET页面查看 CLICK图表点击
  entity_info?: string | number // 信息
  app_url?: string //访问路由
  host?: 'fi' | 'vmp' //default=>vmp 来源
}

export interface IGetChartsSpeedParams {
  id?: number
  pattern?: number
  limit?: number
}
export interface IChartsSpeedInfo {
  avgInfo: {
    chartId: number
    avgSpeed: number
  }[]
  maxInfo: {
    chartId: number
    maxSpeed: number
  }[]
  chart_info?: {
    code_block?: string[]
    correlation_chart_id?: number
    python_codeblock?: string[]
    title?: string
  }
  detail?: string[]
  req_times?: string[]
}
export interface IChartsErrorInfo {
  id: number
  chart_id: number
  path_setting: string
  error_msg: string
  create_time: string
  charts: {
    code_block: any
    python_codeblock: any
    correlation_chart_id: number
    title: string
  }
  others: any
  phone_number: string
}

export const ChartService = {
  // ————图表管理————
  createChart: (data: ITypeChartCreator): IResponse<ITypeChart> =>
    AxiosInstanceFunc({ url: `/charts`, method: 'post', data }),
  deleteChart: (chartID: number): IResponse<any> => AxiosInstanceFunc({ url: `/charts/${chartID}`, method: 'delete' }),
  saveChart: (chartID: number, data: ITypeChartSaver): IResponse<boolean> =>
    AxiosInstanceFunc({ url: `/charts/${chartID}`, method: 'put', data }),
  getChartAll: (params: IGetChartsParams): IResponse<ITypeChart[]> =>
    AxiosInstanceFunc({
      url: `/charts/`,
      method: 'get',
      params
    }),
  getChartInfo: (chartID: number): IResponse<ITypeChart> =>
    AxiosInstanceFunc({ url: `/charts/${chartID}`, method: 'get' }),

  // ————图表收藏————
  collectChart: (data: ICollectChartDatas): IResponse<any> =>
    AxiosInstanceFunc({ url: `/charts/favorites`, method: 'post', data }),
  cancelCollectChart: (chartID: number): IResponse<any> =>
    AxiosInstanceFunc({ url: `/charts/favorites/${chartID}`, method: 'delete' }),
  getCollectedChartAll: (params: IPaginationParams): IResponse<ICollectedChart[]> =>
    AxiosInstanceFunc({
      url: `/charts/favorites`,
      method: 'get',
      params
    }),

  // ————选择项模板————
  createTmplsetting: (data: ITypeTmplsetting): IResponse<ITypeTmplsetting> =>
    AxiosInstanceFunc({
      url: `/charts/tmplsetting/`,
      method: 'post',
      data
    }),
  saveTmplsetting: (tmplsettingID: number, data: ITypeTmplsetting): IResponse<boolean> =>
    AxiosInstanceFunc({
      url: `/charts/tmplsetting/${tmplsettingID}`,
      method: 'put',
      data
    }),
  getTmplsettingAll: (): IResponse<ITypeTmplsetting[]> =>
    AxiosInstanceFunc({
      url: `/charts/tmplsetting/`,
      method: 'get'
    }),
  getTmplsetting: (tmplsettingID: number): IResponse<ITypeTmplsetting> =>
    AxiosInstanceFunc({
      url: `/charts/tmplsetting/${tmplsettingID}`,
      method: 'get'
    }),

  // ————图表统计（点击量、加载时长、图表报错）————
  UserBehaviorMonitor: (data: IUserBehaviorMonitorDatas): IResponse<any> =>
    AxiosInstanceFunc({
      url: `/backend/statistics_access`,
      method: 'post',
      data
    }),
  getChartClicks: (params: { ids: string }): IResponse<IArticleClicks[]> =>
    AxiosInstanceFunc({ url: `/backend/statistics_access/charts/many`, method: 'get', params }),
  getChartsSpeed: (params: IGetChartsSpeedParams): IResponse<IChartsSpeedInfo> =>
    AxiosInstanceFunc({ url: `/backend/statistics_speed/charts`, method: 'get', params }),
  getChartsError: (params: { chart_id?: number; take: number; skip: number }): IResponse<IChartsErrorInfo[]> =>
    AxiosInstanceFunc({ url: `/backend/statistics_err/charts`, method: 'get', params }),
  // ————图表模板————
  setChartAsTempl: (chartID?: number): IResponse<any> =>
    AxiosInstanceFunc({
      url: `/charts/tmpls/cp/${chartID}`,
      method: 'post'
    }),
  deleteChartTempl: (chartTmplID: number): IResponse<any> =>
    AxiosInstanceFunc({
      url: `/charts/tmpls/${chartTmplID}`,
      method: 'delete'
    }),
  saveChartTempl: (chartTmplID: number, data: ITypeChartSaver): IResponse<any> =>
    AxiosInstanceFunc({ url: `/charts/tmpls/${chartTmplID}`, method: 'put', data }),
  getChartTemplInfoAll: ({ take, skip }: IPaginationParams): IResponse<IChartTemplInfoAll[]> =>
    AxiosInstanceFunc({
      url: `/charts/tmpls`,
      method: 'get',
      params: { take: take, skip: skip * take }
    }),
  getChartTemplInfo: (chartTmplID: number): IResponse<ITypeChart> =>
    AxiosInstanceFunc({
      url: `/charts/tmpls/${chartTmplID}`,
      method: 'get'
    }),

  // ————图表代码块————
  createChartCodeBlock: (data: IChartCodeBlockData): IResponse<IChartCodeBlockInfo> =>
    AxiosInstanceFunc({ url: `/charts/dataprocessing/codeblock`, method: 'post', data }),
  deleteChartCodeBlock: (codeblockID: string): IResponse<unknown> =>
    AxiosInstanceFunc({ url: `/charts/dataprocessing/codeblock/${codeblockID}`, method: 'delete' }),
  updateChartCodeBlock: (codeblockID: string, data: IChartCodeBlockData): IResponse<unknown> =>
    AxiosInstanceFunc({ url: `/charts/dataprocessing/codeblock/${codeblockID}`, method: 'put', data }),
  getChartCodeBlockAll: (): IResponse<IChartCodeBlockInfo[]> =>
    AxiosInstanceFunc({ url: '/charts/dataprocessing/codeblock', method: 'get' }),
  getChartCodeBlock: (codeblockID: string): IResponse<IChartCodeBlockInfo> =>
    AxiosInstanceFunc({ url: `/charts/dataprocessing/codeblock/${codeblockID}`, method: 'get' }),

  // ————图表数据————
  generateChartSeries: (chartID: number, data: IGenerateChartSeriesDatas): IResponse<ITypeChartSeries> =>
    AxiosInstanceFunc({
      url: `/charts/${chartID}/data`,
      method: 'post',
      data
    }),
  getChartSeriesBySQL: (chartID: number, data: IGetChartSeriesBySQLDatas): IResponse<ITypeChartSeries> =>
    AxiosInstanceFunc({
      url: `/charts/${chartID}/data_sql`,
      method: 'post',
      data
    }),
  getTmplsettingSeries: (tmplsettingID: number): IResponse<ITypeTmplsettingSeries> =>
    AxiosInstanceFunc({
      url: `/charts/tmplsetting/${tmplsettingID}/data`,
      method: 'get'
    }),

  // ————图表功能————
  // 获取快捷插入的指标信息
  getInsertData: (params: { sec_name: string }): IResponse<IInsertData[]> =>
    AxiosInstanceFunc({
      url: `https://nlpbeta.internal.hzinsights.com/api/edb`,
      method: 'get',
      params
    }),
  // 修改图表封面
  updateChartCover: (chartID: number, { svg }: { svg: string }): IResponse<boolean> =>
    AxiosInstanceFunc({
      url: `/charts/${chartID}/cover`,
      method: 'post',
      data: { svg: svg.replace(/&/g, '&amp;') }
    }),
  // 清除图表缓存
  deleteChartCache: (codeblockID: number): IResponse<unknown> =>
    AxiosInstanceFunc({ url: `/charts/${codeblockID}/cache`, method: 'delete' }),
  // 获取所有图表标签
  getChartTagAll: (): IResponse<IChartPtag[]> =>
    AxiosInstanceFunc({
      url: `/charts/ptag`,
      method: 'get'
    }),
  addChartReview: (data: { chart_id: number; review: string }): IResponse<IChartReview> =>
    AxiosInstanceFunc({ url: `/charts/review`, method: 'POST', data }),
  deleteChartReview: (reviewID: number): IResponse<unknown> =>
    AxiosInstanceFunc({ url: `/charts/review/${reviewID}`, method: 'DELETE' })
}
